import Vue from 'vue'
import Router from 'vue-router'
Vue.use(Router)

export default new Router({
  routes: [
    {
      path: '/',
      redirect: '/home'
    }, {
      path: '/home',
      name: 'home',
      component: resolve => require(['@/view/HomePage'], resolve),
      meta: {
        title: '关于我们',
        title1: 'Company Profile',
        pc: true
      }
    }, {
      path: '/service',
      name: 'service',
      component: resolve => require(['@/view/Service'], resolve),
      meta: {
        title: '产品业务',
        title1: 'Business Collaboration',
        pc: true
      }
    },{
      path: '/business1',
      name: 'business1',
      component: resolve => require(['@/view/Business/business1'], resolve),
      meta: {
        title: '万声全浸音 - 空间音频',
        scrollToTop:true,
        title1: 'Team',
        pc: true
      }
    },{
      path: '/business2',
      name: 'business2',
      component: resolve => require(['@/view/Business/business2'], resolve),
      meta: {
        title: 'AIGC声音实验室',
        scrollToTop:true,
        title1: 'Team',
        pc: true
      }
    },{
      path: '/business3',
      name: 'business3',
      component: resolve => require(['@/view/Business/business3'], resolve),
      meta: {
        title: 'NFT数字音乐藏品',
        scrollToTop:true,
        title1: 'Team',
        pc: true
      }
    },{
      path: '/business4',
      name: 'business4',
      component: resolve => require(['@/view/Business/business4'], resolve),
      meta: {
        title: 'LIVEHOUSE',
        scrollToTop:true,
        title1: 'Team',
        pc: true
      }
    },{
      path: '/business5',
      name: 'business5',
      component: resolve => require(['@/view/Business/business5'], resolve),
      meta: {
        title: 'K歌/直播',
        scrollToTop:true,
        title1: 'Team',
        pc: true
      }
    },{
      path: '/business6',
      name: 'business6',
      component: resolve => require(['@/view/Business/business6'], resolve),
      meta: {
        title: '演艺经纪 & 版权代理',
        title1: 'Team',
        scrollToTop:true,
        pc: true
      }
    },{
      path: '/business7',
      name: 'business7',
      component: resolve => require(['@/view/Business/business7'], resolve),
      meta: {
        title: '万声音乐人平台',
        scrollToTop:true,
        title1: 'Team',
        pc: true
      }
    },{
      path: '/business8',
      name: 'business8',
      component: resolve => require(['@/view/Business/business8'], resolve),
      meta: {
        title: '新闻资讯',
        scrollToTop:true,
        title1: 'Team',
        pc: true
      }
    },{
      path: '/business9',
      name: 'business9',
      component: resolve => require(['@/view/Business/business9'], resolve),
      meta: {
        title: '新闻资讯',
        scrollToTop:true,
        title1: 'Team',
        pc: true
      }
    },{
      path: '/business10',
      name: 'business10',
      component: resolve => require(['@/view/Business/business10'], resolve),
      meta: {
        title: '新闻资讯',
        scrollToTop:true,
        title1: 'Team',
        pc: true
      }
    },{
      path: '/business11',
      name: 'business11',
      component: resolve => require(['@/view/Business/business11'], resolve),
      meta: {
        title: '新闻资讯',
        scrollToTop:true,
        title1: 'Team',
        pc: true
      }
    },{
      path: '/business12',
      name: 'business12',
      component: resolve => require(['@/view/Business/business12'], resolve),
      meta: {
        title: '新闻资讯',
        scrollToTop:true,
        title1: 'Team',
        pc: true
      }
    }, {
      path: '/jobchance',
      name: 'jobchance',
      component: resolve => require(['@/view/JobChance'], resolve),
      meta: {
        title: '加入我们',
        title1: 'Team',
        pc: true
      }
    },{
      path: '/integrated',
      name: 'integrated',
      component: resolve => require(['@/view/integrated'], resolve),
      meta: {
        title: 'The integrated Music Apps',
        title1: 'The integrated Music Apps',
        pc: true
      }
    }, {
      path: '/Instruction',
      name: 'Instruction',
      component: resolve => require(['@/view/Instruction'], resolve),
      meta: {
        title: 'Account Cancellation Instruction',
        title1: 'Account Cancellation Instruction',
        pc: true
      }
    },{
      path: '/jobdetail',
      name: 'jobdetail',
      component: resolve => require(['@/view/JobDetail'], resolve),
      meta: {
        title: '加入我们',
        title1: 'Team',
        pc: true
      }
    }, {
      path: '/privacy-policy-nft-iOS-testflight.html',
      name: 'PrivacyPolicy',
      component: resolve => require(['@/view/PrivacyPolicy'], resolve),
      meta: {
        title: '个人信息保护及隐私政策'
      }
    }, {
      path: '/wbShare',
      name: 'wbShare',
      component: resolve => require(['@/view/wbShare'], resolve),
      meta: {
        title: 'wbShare',
      }
    },
  ],
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      //此处意思 即若回退页面，返回savedPosition，且页面不置顶
      return savedPosition
    } else {
      //此处意思 若页面跳转新页面，则页面置顶
      const position = {}
      if (to.matched.some(m => m.meta.scrollToTop)) {
        position.x = 0
        position.y = 0
      }
      return position
    }
  },
})

<template>
  <div :style="{ 'background': swiperList[navIndex].hide ? '#000' : 'transparent' }">
    <div id="header" ref="header" :class="[{ 'haveBanner':headerFixed||!swiperList[navIndex].hide },{'setbg':swiperList[navIndex].hide&&$store.state.screenWidth> 768}]" class="container-fuild">
      <!-- 电脑导航 -->
      <div class="header-nav">
        <!-- 导航logo -->
        <div class="header-nav-logo">
          <img src="@/assets/img/logo_white.png">
        </div>
        <!-- 导航内容 -->
        <ul class="header-nav-wrapper" v-if="$store.state.screenWidth > 768">
          <li v-for="(item, index) in navList" :key="index" :class="index == navIndex ? 'active' : ''">
            <router-link :to="item.path">
              {{ item.name }}
            </router-link>
          </li>
          <li @click="toggle">
            <a href="javascript:;" :class="{'zhOrEn':this.$i18n.locale !== 'zh'}">
              <i class="zh">中</i>/<i class="en">En</i></a>
          </li>
        </ul>
        <div v-else class="header-small">
          <a href="javascript:;" @click="toggle">
            <i class="zh" v-show="$i18n.locale == 'zh'">中</i><i class="en" v-show="$i18n.locale == 'en'">En</i></a>
          <span @click="CloseEvent">
            <img src="@/assets/img/Frame 128.png" alt="">
          </span>
        </div>
      </div>
    </div>
    <div class="top" v-if="swiperList[navIndex].hide">
    </div>
    <div id="banner" :class="{'hide':swiperList[navIndex].hide}">
      <div 
        :class="['swiper-slide',{ 'home-banner': swiperList[navIndex].homeBanner },{'swiper-slide-two':navIndex>=3}, { 'home-banner1': swiperList[navIndex].homeBanner && navIndex === 0 }, { 'home-banner2': swiperList[navIndex].homeBanner && navIndex === 1 }, { 'home-banner3': swiperList[navIndex].homeBanner && navIndex === 2 }]">
        <img class="swiper-lazy" v-if="!swiperList[navIndex].hide" :src="swiperList[navIndex].src" alt="轮播图">
        <!-- <img class="swiper-lazy"
            :src="swiperList[navIndex].src"
            alt="轮播图" v-if="navIndex<2">
        <video :src="swiperList[navIndex].src" v-if="navIndex==2" autoplay height="480" loop></video> -->
        <div :class="['swiper-slide-title', { 'swiper-slide-title-en': $i18n.locale == 'en' }]"
          v-if="swiperList[navIndex].homeBanner">
          <div v-html='swiperList[navIndex].content'></div>
        </div>
      </div>
    </div>
    <div class="header-phone" v-if="showHeader" @click.stop.prevent="CloseEvent">
      <div class="header-top">
        <div class="img-top">
        <div class="header-nav-logo">
          <img src="@/assets/img/img1.png">
        </div>
        <div class="close" @click="CloseEvent">
          <img src="@/assets/img/list.png">
        </div>
      </div>
      <ul class="phone-List">
          <li v-for="(item, index) in navList" :key="index" :class="{'active' : index == navIndex}">
            <router-link :to="item.path">
              {{ item.name }}
            </router-link>
          </li>
          <li @click="toggle">
            <a href="javascript:;" :class="{'zhOrEn':this.$i18n.locale !== 'zh'}">
              <i class="zh">中</i>/<i class="en">En</i></a>
          </li>
        </ul>
      </div>
     
    </div>
  </div>
</template>
<script>
export default {
  name: "Header",
  data() {
    return {
      navIndex: 0,
      scrollTop:0,
      headerFixed:true,
      showHeader: false,
      menuClass: "glyphicon glyphicon-menu-down",
    };
  },
  props: ['path'],
  watch: {
    path: {
      handler(newVal) {
        switch (newVal) {
          // 不显示标题的都是4，对应hide：true
          case "/home":
            this.navIndex = 0;
            break;
          case "/service":
            this.navIndex = 1;
            break;
          case "/jobchance":
            this.navIndex = 2;
            break;
          case "/jobdetail":
            this.navIndex = 2;
            break;
          case "/business1":
            this.navIndex = 3;
            break;
          case "/business2":
            this.navIndex = 4;
            break;
          case "/business3":
            this.navIndex = 5;
            break;
          case "/business4":
            this.navIndex = 4;
            break;
          case "/business5":
            this.navIndex = 4;
            break;
          case "/business6":
            this.navIndex = 6;
            break;
          case "/business7":
            this.navIndex = 7;
            break;
          case "/business8":
            this.navIndex = 4;
            break;
          case "/business9":
            this.navIndex = 4;
            break;
            case "/business10":
            this.navIndex = 4;
            break;
          case "/business11":
            this.navIndex = 4;
            break;
          case "/business12":
            this.navIndex = 4;
            break;
          default:
            this.navIndex = 0;
        }
        this.setScroll()
      },
      immediate: true //初始化时让handler调用一下
    }
  },
  computed: {
    navList() {
      return [
        {
          name: this.$t('header.name1'),
          path: "/",
        },
        {
          name: this.$t('header.name2'),
          path: "/service",
        },
        {
          name: this.$t('header.name3'),
          path: "/jobchance",
        }
      ]
    },
    swiperList() {
      return [
        {
          src: require("@/assets/img/banner11.png"),
          content: this.$t('banner.title1'),
          srcText: this.$i18n.locale == 'en' ? require("@/assets/img/banner/Text01_en.png") : require("@/assets/img/banner/Text01_cn.png"),
          homeBanner: true,
        },
        {
          src: require("@/assets/img/banner22.png"),
          content: this.$t('banner.title2'),
          srcText: this.$i18n.locale == 'en' ? require("@/assets/img/banner/Text02_en.png") : require("@/assets/img/banner/Text02_cn.png"),
          homeBanner: true,
        },
        {
          src: require("@/assets/img/banner33.png"),
          content: this.$t('banner.title3'),
          srcText: this.$i18n.locale == 'en' ? require("@/assets/img/banner/Text03_en.png") : require("@/assets/img/banner/Text03_cn.png"),
          homeBanner: true,
        },
        {
          src: require("@/assets/img/banner/01.jpg"),
        },
        {
          hide: true,
        },
        {
          src: require("@/assets/img/banner/03.jpg"),
        },
        {
          src: require("@/assets/img/banner/06.jpg"),
        },
        {
          src: require("@/assets/img/banner/07.jpg"),
        },
      ]
    }
  },
  methods: {
    setScroll(){
      try {
        this.scrollTop = document.documentElement.scrollTop || document.body.scrollTop
        if(!this.swiperList[this.navIndex].hide){
          if(this.scrollTop>30){
            this.headerFixed=true;
            let opacity = this.scrollTop/240>1 ?1:this.scrollTop/140
            this.$refs.header.style.background= `rgba(0,0,0,${opacity})`
          }else{
            this.headerFixed=false;
            this.$refs.header.style.background= 'rgba(0,0,0,0)'
          }
          console.log('this.scrollTop',this.headerFixed)
        }else if(this.swiperList[this.navIndex].hide){
          this.$refs.header.style.background= 'rgba(0,0,0,1)'
          if(this.scrollTop>5){
            this.headerFixed=true;
          }else{
            this.headerFixed=false;
          }
        }
      } catch (error) { 
      }
    },
    CloseEvent() {
      this.showHeader = !this.showHeader
    },
    toggle() {
      if (this.$i18n.locale == 'zh') {
        sessionStorage.setItem('language', 'en')
        this.$i18n.locale = sessionStorage.getItem('language')
        document.querySelector(".en").classList.remove("gray")
        document.querySelector(".zh").classList.add("gray")
      } else if (this.$i18n.locale == 'en') {
        sessionStorage.setItem('language', 'zh')
        this.$i18n.locale = sessionStorage.getItem('language')
        document.querySelector(".en").classList.add("gray")
        document.querySelector(".zh").classList.remove("gray")
      }
    },
    menuClick() {
      if (this.menuClass == "glyphicon glyphicon-menu-down") {
        this.menuClass = "glyphicon glyphicon-menu-up";
      } else {
        this.menuClass = "glyphicon glyphicon-menu-down";
      }
    }
  },
  mounted() {
    this.$nextTick(()=>{
      window.addEventListener("scroll", this.setScroll);
    })
    if (this.$i18n.locale == 'en') {
      document.querySelector(".en").classList.remove("gray")
      document.querySelector(".zh").classList.add("gray")
    } else if (this.$i18n.locale == 'zh') {
      document.querySelector(".en").classList.add("gray")
      document.querySelector(".zh").classList.remove("gray")
    }
   
  }
};
</script>
<style scoped lang="less">
.zh,
.en {
  font-style: normal;
}

.gray {
  color: rgba(255, 255, 255, 0.6);
}

/* 顶部 */
#header {
  width: 100%;
  z-index: 1000;
  padding: 12PX 40PX 0;
  box-sizing: border-box;
  margin: 0 auto;
}
.container-fuild{
  position: absolute;
  top: 0;
  left: 0;
}
.haveBanner {
  position: fixed;
  top: 0;
  left: 0;
  height: 60px;
}

.setbg{
  background: #000;
}
/* 导航栏 */
#header .header-nav {
  max-width: 1280PX;
  height: 48PX;
  margin: 0 auto;
}

/* 导航栏logo */
#header .header-nav .header-nav-logo {
  width: 120PX;
  height: 100%;
  float: left;
  position: relative;
}

/* 导航栏logo图片 */
#header .header-nav .header-nav-logo img {
  width: 120PX;
  height: 60PX;
  position: absolute;
  top: -8px;
  left: 0;
  right: 0;
  bottom: 0;
  // margin: auto;
}

/* 导航栏 导航容器 */
#header .header-nav-fixed .header-nav-wrapper {
  line-height: 50PX;
}

#header .header-nav .header-nav-wrapper {
  line-height: 48PX;
  float: right;
  margin: 0;
  width: 60%;
  max-width: 638PX;
  display: flex;
  justify-content: space-between;
}

/* 导航栏 每个导航 */
#header .header-nav .header-nav-wrapper>li {
  float: left;
  position: relative;
}

/* 导航栏 每个导航下面的 a 链接 */
#header .header-nav .header-nav-wrapper>li>a {
  color: #FFF;
  /* font-family: PingFang SC; */
  font-size: 18PX;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-bottom: 8PX;
  position: relative;
  text-decoration: none;
}
#header .header-nav .header-nav-wrapper>li a{
        .zh{
          opacity: 1;
        }
        .en{
          opacity: 0.5;
        }
      }
#header .header-nav .header-nav-wrapper>li .zhOrEn{
        .zh{
          opacity: 0.5;
        }
        .en{
          opacity: 1;
        }
      }
/* 导航栏 每个导航下面的 a 链接的右侧小三角 */
#header .header-nav .header-nav-wrapper>li>a>span {
  font-size: 12PX;
  transition: transform ease 0.5s;
}

/* 导航栏 每个导航下面的 a 链接 鼠标滑上去三角标的样式 */
#header .header-nav .header-nav-wrapper>li>a:hover span {
  transform: rotate(180deg);
}

/* 导航栏 每个导航下面的 a 链接 鼠标点击后的样式 */
#header .header-nav .header-nav-wrapper>li.active>a {
  border-bottom: 2PX solid #fff;
}

#banner {
  width: 100%;
  height: 100%;
  color: #fff;
}

.swiper-slide {
  position: relative;
  width: 100%;
  /* max-height: 480PX; */
  overflow: hidden;
  /* background-color: #000; */
  text-align: center;
}

.swiper-slide img {
  width: 100%;
  height: 480px;
  object-fit:cover;
}
.swiper-slide-two img {
  width: 100%;
  height: 360px;
  object-fit:cover;
}
.home-banner1 {
  height: 480PX;
  background: linear-gradient(77deg, #922424 -29.94%, #020107 97.68%), linear-gradient(97deg, #9A0D32 -37.5%, #0E1427 51.32%) !important;
}

.home-banner2 {
  height: 480PX;
  background: linear-gradient(98deg, #9A0D32 -11.93%, rgba(14, 20, 39, 0.00) 59.53%), linear-gradient(171deg, #1C3453 6.98%, #192E4B 55.47%, #030913 89.52%) !important;
}

.home-banner3 {
  height: 480PX;
  background: linear-gradient(78deg, #DD2E45 -1.1%, #141821 47.92%, #131720 98.7%) !important;
}

.home-banner img {
  width: 560PX;
  height: 480PX;
  position: absolute;
  right: 0;
  bottom: 0;
}

.swiper-slide-title {
  position: relative;
  z-index: 99;
  text-align: left;
  line-height: 80PX;
  max-width: 1280PX;
  height: 100%;
  margin: 0 auto;
  color: #FFF;
  font-family:-apple-system, system-ui, BlinkMacSystemFont, 'Alimama ShuHeiTi';
  font-size: 48PX;
  font-style: normal;
  font-weight: 600;
  line-height: 72PX;
  letter-spacing: 10px;
}
.swiper-slide-title-en{
  font-size: 36PX;
  line-height: 56PX;
  letter-spacing: 0;
}
.swiper-slide-title div {
  position: absolute;
  bottom: 124PX;
  left: 96PX;
  background: radial-gradient(43.59% 58.13% at 50% 54.17%, rgba(0, 0, 0, 0.30) 0%, rgba(0, 0, 0, 0.00) 100%);
}
.top{
  height: 60px;
  width: 100%;
}

.swiper-slide-title p {
  margin-bottom: 16PX;
}
.header-small {
    display: flex;
    height: 34PX;
    align-items: center;

  }

  .header-small a {
    font-size: 20PX;
    color: #fff;
    text-decoration:none;
  }

  .header-small span img {
    width: 20PX;
    height: auto;
    margin-left: 16PX;
  }
  .phoneClass  .header-nav {
    display: flex;
    justify-content: space-between;
    align-items: center
  }
@media screen and (max-width:768PX) {
  .top{
    height: 82px;
    width: 100%;
  }
  #header {
    height: 82PX;
    padding: 24PX;
    width: 100%;
    top:0;
    z-index: 100;
    box-sizing: border-box;
    .header-nav{
      max-width: 100%;
      box-sizing: border-box;
      width: 100%;
    }
  }

  #banner {
    height: 240px;
  }
  .hide{
    height: 0 !important;
  }
  .swiper-slide{
    height: 240px;
    img{
      height: 240px;
      object-fit:cover
    }
  }
  .swiper-slide-title {
    position: relative;
    z-index: 99;
    text-align: left;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    color: #FFF;
    font-family:-apple-system, system-ui, BlinkMacSystemFont, 'Alimama ShuHeiTi';
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
    text-align: left;
  }

  .swiper-slide-title div {
    position: absolute;
    bottom: 42px;
    left: 10%;
    letter-spacing: 4px;
    width: 80%;
    background: radial-gradient(43.59% 58.13% at 50% 54.17%, rgba(0, 0, 0, 0.30) 0%, rgba(0, 0, 0, 0.00) 100%);
  }

  .swiper-slide-title-en div {
    letter-spacing: normal;
    font-size: 20px;
  }

  .swiper-slide-title p {
    text-align: center;
    margin-bottom: 16px;
  }

  .header-nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .header-small {
    display: flex;
    height: 24px;
    align-items: center;
  }

  .header-small a {
    font-size: 20PX;
    color: #fff;
  }

  .header-small span img {
    width: 20PX;
    height: auto;
    margin-left: 16PX;
    position: relative;
    top: -2px;
  }

  .home-banner1 {
    height: 240px;
    background: radial-gradient(43.59% 58.13% at 50% 54.17%, rgba(0, 0, 0, 0.30) 0%, rgba(0, 0, 0, 0.00) 100%), linear-gradient(115deg, #922424 13.17%, rgba(2, 1, 7, 0.00) 58.08%),  #000 !important;
  }

  .home-banner2 {
    height: 240px;
    background: radial-gradient(19.3% 47.11% at 58.4% 53.65%, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.30) 100%), linear-gradient(98deg, #9A0D32 -11.93%, rgba(14, 20, 39, 0.00) 59.53%),  #000!important;
  }

  .home-banner3 {
    height: 240px;
    background: radial-gradient(19.3% 47.11% at 58.4% 53.65%, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.30) 100%), linear-gradient(78deg, #DD2E45 -1.1%, rgba(20, 24, 33, 0.00) 47.92%, rgba(19, 23, 32, 0.00) 98.7%), #000 !important;
  }

  .home-banner {
    height: 240px;
  }

  .home-banner img {
    height: 240px;
    width: auto;
  }

  .header-phone {
    width: 100%;
    position: fixed;
    z-index: 99999;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    height: 100%;
    background: rgba(0, 0, 0, 0.10);
    overflow: hidden;
    .header-top{
      background: #FFF;
      box-shadow: 0px -2px 0px 0px rgba(0, 0, 0, 0.00) inset;
      height: auto;
      animation:topshow ease 0.2s ;
    }
    .phone-List li{
      padding: 0 24PX;
      color:  rgba(0, 0, 0, 0.55);
    
      font-style: normal;
      font-weight: 400;
      a{
        color:inherit;
        color:  rgba(0, 0, 0, 0.55);
        display: inline-block;
        line-height: 28px;
        padding: 16PX 0;
        font-size: 16px;
        width: 100%;
        text-decoration: none;
        .zh{
          opacity: 1;
        }
        .en{
          opacity: 0.3;
        }
      }
      .zhOrEn{
        .zh{
          opacity: 0.3;
        }
        .en{
          opacity: 1;
        }
      }
    }
    .phone-List .active a{
        color: #E62318;
    }
    .header-nav-logo img {
      margin-top: -8px;
    }
  }

  .img-top {
    display: flex;
    justify-content: space-between;
    padding: 24PX;
    height: 82PX;
  }

  .header-nav-logo img {
    width: 120PX;
    height: 60PX;
  }

  .close {
    width: 24PX;
    height: 34PX;
    padding: 5px 2px;
    img{
      width: 24px;
      height: 24px;
    }
  }
}

@keyframes topshow {
  0%   { height: 0; }
  1000%  { top: 306px; }
}
</style>
